import React from "react";
import URLS from "./store/constants/urls";

// const SignUp = React.lazy(() =>
//   import("./App/views/Authentication/SignUp/SignUp")
// );
// const SignIn = React.lazy(() =>
//   import("./App/views/Authentication/SignIn/SignIn")
// );
const Landing = React.lazy(() =>
  import("./App/layout/Landing")
);
const AdminLayout = React.lazy(() =>
  import("./App/layout/AdminLayout")
);
const CGU = React.lazy(() =>
  import("./App/layout/Legal/Cgu")
);
const CGV = React.lazy(() =>
  import("./App/layout/Legal/Cgv")
);
const Cookies = React.lazy(() =>
  import("./App/layout/Legal/Cookies")
);
const ErrorPage = React.lazy(() =>
  import("./App/layout/Error/NotFound")
);
const Blogs = React.lazy(() =>
  import("./App/layout/Blogs")
);

const route = [
  // { path: URLS.SIGNUP, exact: true, name: "Signup", component: SignUp },
  { path: URLS.SOURCE, exact: true, name: "Source", component: Landing },
  { path: URLS.ADMIN, exact: false, name: "AdminLayout", component: AdminLayout },
  { path: URLS.CGU, exact: true, name: "Cgu", component: CGU },
  { path: URLS.CGV, exact: true, name: "Cgv", component: CGV },
  { path: URLS.Cookies, exact: true, name: "Cookies", component: Cookies },
  { path: URLS.ERROR, exact: true, name: "Error", component: ErrorPage },
  { path: URLS.BLOGS, exact: true, name: "Blogs", component: Blogs },
];

export default route;
