import React, { useEffect, useState } from "react";
import { setNotification } from "../../../store/actions/common";
import { Container } from "react-bootstrap";
import { get } from "../../../repositories/blogs";
import '../Common/css/styles.scss';

import { dispatch } from "../../../store";
import { Helmet } from "react-helmet";
import "./article.css"
import Header from "../Common/header";

const Article = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [blog, setBlog] = useState();

    useEffect(() => {
        try {
            if (id) {
                setIsLoading(true);
                get(id).then(resp => {
                    setBlog(resp)
                    setIsLoading(false);
                })
            };
        } catch (error) {
            setIsLoading(false);
            notify(false, error);
        }
        // eslint-disable-next-line
    }, []);

    const notify = (isSuccess, message) => {
        dispatch(
            setNotification({
                isSuccess: isSuccess,
                message: message,
            })
        );
    };

    return (
        <div className="wrapper">
            {blog &&
                <Helmet>
                    <title>{blog.title}</title>
                    <meta name="description" content={blog.description} />
                    <meta name="keywords" content={blog.keywords} />
                    <meta property="og:title" content={blog.ogTitle} />
                    <meta property="og:description" content={blog.ogDescription} />
                    {/* <meta property="og:image" content="image_url.jpg" /> */}
                    {/* Add any other meta tags or SEO information here */}
                </Helmet>}
            <Header />
            <Container>
                {blog &&
                    <div>

                        <div className="article"
                            dangerouslySetInnerHTML={{ __html: blog.content }}
                        />
                    </div>}
            </Container>
        </div>
    );
};

export default Article;
