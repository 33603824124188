import React, { useEffect, useState } from "react";
import URLS from "../../../store/constants/urls";
import user_undefined from "../../../assets/images/user/user_undefined.png";
import i18next from "i18next";
import logo from "../../../assets/logo.svg";
import { useSelector } from "react-redux";
import useKeycloakService from "../../../repositories/accessManagement/Keycloak";

const Header = () => {
    const user = useSelector((state) => state.authentication.user.data);
    const { login, authenticated, initialized } = useKeycloakService();
    const [profilePicture, seProfilePicture] = useState(user_undefined);

    useEffect(() => {
        seProfilePicture(
            user.pictureContent
                ? user.pictureContent
                : user_undefined
        );
        // eslint-disable-next-line
    }, [user]);

    return (<nav className="navbar landing-nav navbar-expand-lg navbar-light navbar-default navbar-fixed-top past-main" style={{ display: "flex" }} role="navigation">
        <div className="container" style={{ marginTop: "8px" }}>
            <a href={URLS.SOURCE} className="b-brand">
                <div style={{
                    width: "180px",
                    position: "fixed",
                    top: "-41px"
                }}>
                    <img src={logo} alt="octolance-logo" />
                </div>
            </a>
            <div style={{ marginLeft: "auto" }}>
                <a href={URLS.SOURCE}>
                    <div>
                        <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                            <div>
                                {initialized && authenticated &&
                                    <div className="pro-head">
                                        {!user.lastName && !user.firstName &&
                                            <a href={URLS.DASHBOARD_DEFAULT}>
                                                <div>
                                                    <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                        {i18next.t("Admin")}
                                                    </span>
                                                </div>
                                            </a>}
                                        {(user.lastName || user.firstName) &&
                                            <div className="row d-flex align-items-center">
                                                <div>
                                                    <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                        {user.lastName}
                                                    </span>
                                                    <div style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                        {user.firstName}
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <a href={URLS.DASHBOARD_DEFAULT}>
                                                        <img
                                                            style={{ height: "40px", width: "40px" }}
                                                            className="rounded-circle"
                                                            src={profilePicture}
                                                            alt=""
                                                            role="presentation"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                            </div>}
                                    </div>
                                }
                                {initialized && !authenticated &&
                                    <a onClick={login} href={URLS.BLANK_LINK}>
                                        <div>
                                            <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                {i18next.t("Sign In")}
                                            </span>
                                        </div>
                                    </a>
                                }
                            </div>
                        </span>
                    </div>
                </a>
            </div>
        </div>
    </nav>
    );
};

export default Header;
