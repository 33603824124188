import { FETCH_DYNAMIC_URLS } from "../../constants/actions-types";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { of, from, merge } from "rxjs";
import { ofType } from "redux-observable";

import { setDynamicUrls } from "../../actions/blogs";
import { setNotification } from "../../actions/common";
import { getActiveBlogs } from "../../../repositories/blogs";

const fetchDynamicUrls = (action$) =>
  action$.pipe(
    ofType(FETCH_DYNAMIC_URLS),
    mergeMap(() =>
      merge(
        from(getActiveBlogs()).pipe(
          flatMap((resp) => of(setDynamicUrls(getBlogsData(resp)))),
          catchError((error) =>
            of(
              setNotification({
                isSuccess: false,
                message: "Error : " + error,
              })
            )
          )
        )
      )
    )
  );

const getBlogsData = (data) => {
  const result = [];
  data.forEach(element => {
    element.blogs.forEach(b => result.push(b))
  });

  return result
}
export default fetchDynamicUrls;
