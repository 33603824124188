import { SET_DYNAMIC_URLS, SET_OPTIONSET } from "../../constants/actions-types";

const initialState = {
  optionSet: [],
  dynamicUrls: [],
};

const handlers = {
  [SET_OPTIONSET](state, payload) {
    return {
      ...state,
      optionSet: payload,
    };
  },
  [SET_DYNAMIC_URLS](state, payload) {
    return {
      ...state,
      dynamicUrls: payload,
    };
  },
  // [SIGNOUT]() {
  //   return initialState;
  // },
};

const ConfigurationReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default ConfigurationReducer;
