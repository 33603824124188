import {
  FETCH_DYNAMIC_URLS,
  SET_DYNAMIC_URLS
} from "../../constants/actions-types";

export const fetchDynamicUrls = () => ({
  type: FETCH_DYNAMIC_URLS,
  payload: false,
});

export const setDynamicUrls = (data) => ({
  type: SET_DYNAMIC_URLS,
  payload: data,
});