import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
// import Loadable from "react-loadable";
import i18n from "../i18n";
import { I18nextProvider } from "react-i18next";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./components/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./components/ScrollToTop";
import publicRoutes from "../route";
import adminRoutes from "../routes";
import Notification from "./components/Notification/index";
import { dispatch } from "../store";
import URLS from "../store/constants/urls";
import Article from "./layout/Blogs/article";
import { fetchDynamicUrls } from "../store/actions/blogs";

const App = () => {
  let history = useHistory();
  const locale = useSelector((state) => state.authentication.params.preferredLanguage);
  const dynamicUrls = useSelector((state) => state.configuration.dynamicUrls);
  const [dynamicMenu, setDynamicMenu] = useState([]);

  useEffect(() => {
    dispatch(fetchDynamicUrls())
  }, []);

  useEffect(() => {
    if (dynamicUrls.length > 0) {
      const currentPath = window.location.pathname;
      const isAuthorizedPath = [...[{ path: URLS.SOURCE }], ...publicRoutes, ...adminRoutes, ...dynamicUrls, ...dynamicUrls.map(route => ({ path: route.url }))].some((route) => route.path === currentPath);
      if (!isAuthorizedPath) { history.push(URLS.ERROR); return; }
    }
    setDynamicMenu(dynamicUrls.map((route, index) => {
      return <Route
        key={index}
        path={route.url}
        exact={true}
        name={route.url}
        render={() => <Article id={route.id} />}
      />
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicUrls])


  const menu = publicRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  const RemoveTrailingSlash = ({ location }) => {
    if (location.pathname !== "/" && location.pathname.match(/\/$/)) {
      return <Redirect to={location.pathname.replace(/\/$/, "")} />;
    }
    return null;
  };

  return (
    <Aux>
      <I18nextProvider i18n={i18n(locale)}>
        <Notification />
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Route path="*" component={RemoveTrailingSlash} />
            <Switch>
              {menu}
              {dynamicMenu}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </I18nextProvider>
    </Aux>
  );
};

export default App;
