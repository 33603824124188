import { Get, Post, Put, Delete, tokenless } from "../../utils/base-api-request";

const getBlogsUrl = process.env.REACT_APP_BASE_API + "api/blog/all";

const getPublicBlogsUrl = process.env.REACT_APP_BASE_API + "api/blog/all/public";
const getBlogsByThemeUrl = process.env.REACT_APP_BASE_API + "api/blog/all/{themeId}";
const getAllLandingBlogsUrl = process.env.REACT_APP_BASE_API + "api/blog/landing";

const getBlogUrl = process.env.REACT_APP_BASE_API + "api/blog/{id}";
const addBlogUrl = process.env.REACT_APP_BASE_API + "api/blog";
const updateBlogUrl = process.env.REACT_APP_BASE_API + "api/blog";
const removeBlogUrl = process.env.REACT_APP_BASE_API + "api/blog/{id}";
const generateBlogUrl = process.env.REACT_APP_BASE_API + "api/blog/generate/{topicId}";

export const getActiveBlogs = async () => {
  return await Get(getPublicBlogsUrl, { [tokenless]: true, "X-API-KEY": process.env.REACT_APP_API_KEY });
};

export const getAll = async () => {
  return await Get(getBlogsUrl);
};

export const GetAllLandingBlogs = async () => {
  return await Get(getAllLandingBlogsUrl, { [tokenless]: true, "X-API-KEY": process.env.REACT_APP_API_KEY });
};

export const getByTheme = async (themeId) => {
  return await Get(
    getBlogsByThemeUrl.replace("{themeId}", themeId)
  );
};

export const get = async (id) => {
  return await Get(getBlogUrl.replace("{id}", id), { [tokenless]: true, "X-API-KEY": process.env.REACT_APP_API_KEY });
};

export const add = async (blog) => {
  return await Post(addBlogUrl, blog);
};

export const generateBlog = async (topicId) => {
  return await Post(generateBlogUrl.replace("{topicId}", topicId));
};

export const update = async (blog) => {
  return await Put(updateBlogUrl, blog);
};

export const remove = async (id) => {
  return await Delete(removeBlogUrl.replace("{id}", id));
};

