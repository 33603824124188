/*---------------------------------Common--------------------------*/
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_LOADING = "SET_LOADING";
export const FETCH_DATA = "FETCH_DATA";
export const SET_URL = "SET_URL";
export const SEND_MAIL = "SET_MAIL";

/*-------------------------User-------------------------*/
export const SIGN_IN = "SIGN_IN";
export const FETCH_USER = "FETCH_USER";
export const SET_USER_ABILITY = "SET_USER_ABILITY";
export const SET_USER_DATA = "SET_USER_DATA";
export const SIGN_UP = "SIGN_UP";
export const RESET_USER = "RESET_USER";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_SIGN_UP = "SET_SIGN_UP";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_CREDENTIALS = "UPDATE_USER_CREDENTIALS";
export const GET_USER_PICTURE = "GET_USER_PICTURE";
export const UPDATE_USER_PICTURE = "UPDATE_USER_PICTURE";
export const SET_USER_PICTURE = "SET_USER_PICTURE";
export const UPDATE_USER_ACTIVE = "UPDATE_USER_ACTIVE";
export const UPDATE_PARAMS = "UPDATE_PARAMS";
export const SET_PARAMS = "SET_PARAMS";
export const SET_AUTH = "SET_AUTH";
export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

/*-------------------------Subscriptions-----------------------------*/
export const FETCH_SUBPRODUCTS = "FETCH_SUBPRODUCTS";
export const SET_SUBPRODUCTS = "SET_SUBPRODUCTS";
export const FETCH_USER_SUBSCRIPTION = "FETCH_USER_SUBSCRIPTION";
export const SET_USER_SUBSCRIPTION = "SET_USER_SUBSCRIPTION";

/*-------------------------OptionSet-----------------------------*/
export const FETCH_OPTIONSET = "FETCH_OPTIONSET";
export const SET_OPTIONSET = "SET_OPTIONSET";
export const ADD_OPTIONSET = "ADD_OPTIONSET";
export const UPDATE_OPTIONSET = "UPDATE_OPTIONSET";
export const DELETE_OPTIONSET = "DELETE_OPTIONSET";

/*--------------------------Contact------------------------------*/
export const FETCH_CONTACT = "FETCH_CONTACT";
export const SET_CONTACT = "SET_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

/*--------------------------Offer------------------------------*/
export const FETCH_OFFER = "FETCH_OFFER";
export const SET_OFFER = "SET_OFFER";
export const ADD_OFFER = "ADD_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";

/*--------------------------Company------------------------------*/
export const FETCH_COMPANY = "FETCH_COMPANY";
export const SET_COMPANY = "SET_COMPANY";
export const ADD_COMPANY = "ADD_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const SET_COMPANY_SUGGESTIONS = "SET_COMPANY_SUGGESTIONS";
export const FETCH_COMPANY_SUGGESTIONS = "FETCH_COMPANY_SUGGESTIONS";

/*------------------------Cv - Profile----------------------------*/
export const FETCH_PROFILE = "FETCH_PROFILE";
export const SET_PROFILE = "SET_PROFILE";
export const ADD_PROFILE = "ADD_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";

/*------------------------Cv - Certification----------------------------*/
export const FETCH_CERTIFICATION = "FETCH_CERTIFICATION";
export const SET_CERTIFICATION = "SET_CERTIFICATION";
export const ADD_CERTIFICATION = "ADD_CERTIFICATION";
export const UPDATE_CERTIFICATION = "UPDATE_CERTIFICATION";
export const DELETE_CERTIFICATION = "DELETE_CERTIFICATION";

/*------------------------Cv - Mission----------------------------*/
export const FETCH_MISSION = "FETCH_MISSION";
export const SET_MISSION = "SET_MISSION";
export const ADD_MISSION = "ADD_MISSION";
export const UPDATE_MISSION = "UPDATE_MISSION";
export const DELETE_MISSION = "DELETE_MISSION";

/*------------------------Cv - Interest----------------------------*/
export const FETCH_INTEREST = "FETCH_INTEREST";
export const SET_INTEREST = "SET_INTEREST";
export const ADD_INTEREST = "ADD_INTEREST";
export const UPDATE_INTEREST = "UPDATE_INTEREST";
export const DELETE_INTEREST = "DELETE_INTEREST";

/*------------------------Cv - Language----------------------------*/
export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

/*------------------------Cv - Skill----------------------------*/
export const FETCH_SKILL = "FETCH_SKILL";
export const SET_SKILL = "SET_SKILL";
export const ADD_SKILL = "ADD_SKILL";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";

/*------------------------Cv - Study----------------------------*/
export const FETCH_STUDY = "FETCH_STUDY";
export const SET_STUDY = "SET_STUDY";
export const ADD_STUDY = "ADD_STUDY";
export const UPDATE_STUDY = "UPDATE_STUDY";
export const DELETE_STUDY = "DELETE_STUDY";

/*------------------------Cv - Training----------------------------*/
export const FETCH_TRAINING = "FETCH_TRAINING";
export const SET_TRAINING = "SET_TRAINING";
export const ADD_TRAINING = "ADD_TRAINING";
export const UPDATE_TRAINING = "UPDATE_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";

/*------------------------ProfessionalInfo----------------------------*/
export const FETCH_PROFESSIONALINFO = "FETCH_PROFESSIONALINFO";
export const SET_PROFESSIONALINFO = "SET_PROFESSIONALINFO";
export const ADD_PROFESSIONALINFO = "ADD_PROFESSIONALINFO";
export const UPDATE_PROFESSIONALINFO = "UPDATE_PROFESSIONALINFO";
export const DELETE_PROFESSIONALINFO = "DELETE_PROFESSIONALINFO";
export const UPDATE_TVAINFO = "UPDATE_TVAINFO";
export const UPDATE_BANKINFO = "UPDATE_BANKINFO";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const SET_PROFESSIONALINFO_LOGO = "SET_PROFESSIONALINFO_LOGO";
export const UPDATE_SIGNATURE = "UPDATE_SIGNATURE";
export const SET_PROFESSIONALINFO_SIGNATURE = "SET_PROFESSIONALINFO_SIGNATURE";

/*------------------------Reporting - Project----------------------------*/
export const FETCH_PROJECT = "FETCH_PROJECT";
export const SET_PROJECT = "SET_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

/*------------------------------------Bill-------------------------------*/
export const FETCH_BILL = "FETCH_BILL";
export const SET_BILL = "SET_BILL";
export const ADD_BILL = "ADD_BILL";
export const UPDATE_BILL = "UPDATE_BILL";
export const DELETE_BILL = "DELETE_BILL";
export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const SET_STATISTICS = "SET_STATISTICS";

/*------------------------------------TRANSACTION-------------------------------*/
export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const SET_INCOMES = "SET_INCOMES";
export const SET_OUTCOMES = "SET_OUTCOMES";

/*----------------------------------------CHARGE-------------------------------*/
export const SET_CHARGE = "SET_CHARGE";

/*----------------------------------------PLANNING-------------------------------*/
export const FETCH_EVENT = "FETCH_EVENT";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const DISMISS_EVENT = "DISMISS_EVENT";
export const SET_EVENT = "SET_EVENT";

/*----------------------------------------BLOGS-------------------------------*/
export const FETCH_DYNAMIC_URLS = "FETCH_DYNAMIC_URLS";
export const SET_DYNAMIC_URLS = "SET_DYNAMIC_URLS";
